<template>
  <div class="Orderlist">
    <div class="top-title">
      <div class="top-text">工单列表</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
 
         <el-form-item label="标题" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>

         <el-form-item label="会员名称" >
                  <el-input v-model="seeks.b"></el-input>
          </el-form-item>

         <el-form-item label="工厂名称" >
                  <el-input v-model="seeks.d"></el-input>
          </el-form-item>


              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
      
    </div>

    <div class="table-tap">
       <ul>
          <li v-for="(item,i) in taplist" :key="i"> <span @click="tap(item)" :class="classactive == item.tapid?'active':''">{{item.tapname}}</span></li>
        </ul>
    </div>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="true_name" label="会员姓名"> </el-table-column>
        <el-table-column prop="bsr_employername" label="工厂名称"> </el-table-column>
        <el-table-column prop="bs_companyname" label="招聘方"> </el-table-column>
        <el-table-column prop="wkhours_sum" label="工时">
            <template slot-scope="scope">
               <span>{{parseFloat(scope.row.wkhours_sum).toFixed(1)}}</span>
            </template>
           </el-table-column>
        <el-table-column prop="price_toplat" label="费用"> </el-table-column>
        <el-table-column prop="price" label="工资"> </el-table-column>
        <el-table-column prop="status_name" label="状态"> </el-table-column>
        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"  type="text"
              >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
export default {
  data() {
    return {

      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      // 下拉框
      seeks: {},
      classactive:0,

      taplist:fakelist.lookinvite.one,

      list:[],
    };
  },
  created() {
     this.getSong();
  },
  mounted() {

  },

  methods: {


    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'TodoWorkEntity',
        handleMode:'fetchallByPlater',
        status:this.classactive,
        query:{
          recruitTitle:this.seeks.a,
          memberName:this.seeks.b,
          employerName:this.seeks.d, 
        }, })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },


    // 跳转详情页
    handleClick(row) {
          this.$router.push({
          path: '/home/orderlistpar',
          query: {
            id: row.workid,
            status:row.status,
          }
        })
    },
 
   // 选项卡
      tap(e){
       this.classactive = e.tapid
       this.getSong();
      },

    // 搜索栏
    seek(){
      this.currentPage = 1;
        this.getSong();
    },




    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.table-tap{
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul{
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 150px;
  text-align: center;
}
.table-tap ul li span{
font-size: 18px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: rgb(255, 187, 0);
  font-size: 16px;
}
@import "../../assets/css/liststyle.css";
</style>